import { useMemo, useState, Fragment, useLayoutEffect } from 'react'
import { QueryClientProvider, useMutation } from 'react-query'
import { formatMoney } from './Money'
import { useOrder, getQueryClient } from './OrderHooks'
import { useTranslation } from 'react-i18next'

const frontendBaseUrl = 'https://' + window.location.hostname
const queryClient = getQueryClient()

export const ApplyVoucher = () => (
  <QueryClientProvider client={queryClient}>
    <ApplyVoucherForm />
  </QueryClientProvider>
)

const ApplyVoucherForm = () => {
  const { data: { data: basket = {} } = {}, isLoading: isLoadingBasket } = useOrder()
  const { payments = [], totals = {} } = basket
  const [voucherInputVisible, setVoucherInputVisible] = useState(false)
  const { mutate: applyVoucherMutation, isLoading: isApplyingVoucher } = useApplyVoucher()
  const { mutate: removeVoucherMutation } = useRemoveVoucher()
  const [displayError, setDisplayError] = useState(null)
  const { t } = useTranslation()

  const appliedVouchers = useMemo(() => {
    return payments.filter(
      (payment) => payment.processor === 'voucher' || payment.processor === 'external_voucher'
    )
  }, [payments])

  const handleApply = (voucherCode) => {
    setDisplayError(null)

    if (!voucherCode) {
      return
    }

    applyVoucherMutation(voucherCode, {
      onSuccess: () => {
        window.location.reload()
        setVoucherInputVisible(false)
      },
      onError: (e) => {
        e.json().then((body) => setDisplayError(body?.message))
      },
    })
  }

  const handleRemove = (voucherId) => {
    removeVoucherMutation(voucherId, {
      onSuccess: () => {
        window.location.reload()
      },
    })
  }

  if (isLoadingBasket) {
    return <div className="bg-gray-200 animate-pulse h-8 w-full" />
  }

  return (
    <div className="w-full">
      {totals.applied_vouchers > 0 && (
        <>
          {appliedVouchers.map((voucher) => (
            <div className="flex py-2">
              <Fragment key={voucher.id}>
                <dt className="flex space-x-3">
                  <span>
                    {t('frontend.apply_voucher.applied_voucher')}
                    {` `}
                  </span>
                  <button
                    className="text-xs font-medium text-gray-700"
                    onClick={() => handleRemove(voucher.id)}
                    title={t('frontend.apply_voucher.remove_voucher')}
                  >
                    {t('frontend.apply_voucher.remove')}
                  </button>
                </dt>
                <dd className="text-gray-900 ml-auto">
                  {formatMoney({
                    amount: voucher.amount * -1,
                    currency: basket.currency,
                  })}
                </dd>
              </Fragment>
            </div>
          ))}
        </>
      )}
      {!voucherInputVisible && (
        <>
          <dt className="w-full">
            <button
              className="text-sm block w-full font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-md shadow-sm p-2"
              title={t('frontend.apply_voucher.apply_a_voucher')}
              onClick={() => setVoucherInputVisible(true)}
            >
              {t('frontend.apply_voucher.apply_a_voucher')}
            </button>
          </dt>
        </>
      )}
      {voucherInputVisible && (
        <div className="py-2">
          <VoucherInput error={displayError} onApply={handleApply} applying={isApplyingVoucher} />
        </div>
      )}
    </div>
  )
}

const VoucherInput = ({ error, onApply, applying }) => {
  const [voucherCode, setVoucherCode] = useState('')
  const [inputRef, setInputRef] = useState(null)
  const { t } = useTranslation()

  useLayoutEffect(() => {
    if (inputRef) inputRef.focus()
  }, [inputRef])

  const handleSubmit = (e) => {
    e.preventDefault()
    onApply(voucherCode)
  }

  return (
    <div className="flex flex-col space-y-2 w-full">
      {error && <div className="text-sm text-red-700">{error}</div>}
      <form onSubmit={handleSubmit} className="w-full flex -space-x-px items-center">
        <input
          type="text"
          name="voucher_code"
          value={voucherCode}
          ref={setInputRef}
          id="voucherCode"
          autoComplete="false"
          placeholder={t('frontend.apply_voucher.voucher_code')}
          className="focus:ring-accent focus:border-accent relative block w-full rounded-l-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
          onChange={(event) => {
            setVoucherCode(event.target.value)
          }}
        />
        <button
          type="submit"
          className="focus:outline-none focus:ring-1 focus:ring-accent focus:border-accent whitespace-nowrap flex justify-center px-4 py-2 rounded-r-md shadow-none sm:text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 h-full flex-col z-10 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => onApply(voucherCode)}
          disabled={applying}
        >
          <span>{t('frontend.apply_voucher.apply')}</span>
        </button>
      </form>
    </div>
  )
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const useApplyVoucher = () =>
  useMutation(async (voucherCode) => {
    const req = await fetch(frontendBaseUrl + '/basket/apply-voucher', {
      method: 'post',
      headers,
      body: JSON.stringify({
        code: voucherCode,
      }),
      credentials: 'include',
    })

    if (req.status > 299) throw req

    return req
  })

const useRemoveVoucher = () =>
  useMutation(async (voucherId) => {
    const req = await fetch(`${frontendBaseUrl}/basket/remove-voucher/${voucherId}`, {
      method: 'post',
      headers,
      credentials: 'include',
    })

    if (req.status > 299) throw req

    return req
  })
