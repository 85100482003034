var DayButton = function (_a) {
    var date = _a.date, onSelect = _a.onSelect, selected = _a.selected;
    return (<button onClick={function () { return onSelect(date); }} className={(selected ? 'bg-accent' : 'hover:bg-accent/10') + " group text-center p-2 rounded-md cursor-pointer focus:outline-none focus:border-accent focus:ring-1 focus:ring-offset-1 focus:ring-accent"}>
      <div className={(selected ? 'text-on-accent' : 'group-hover:text-accent/50') + " text-xs text-gray-500"}>
        {date.toFormat('ccc')}
      </div>
      <div className={(selected ? 'text-on-accent' : 'text-gray-900') + " text-sm font-medium"}>
        {date.toFormat('d')}
      </div>
    </button>);
};
export var WeekDayPicker = function (_a) {
    var dateFrom = _a.dateFrom, dateTo = _a.dateTo, onDateSelected = _a.onDateSelected, selected = _a.selected;
    return (<div className="p-1 space-x-1 border border-gray-200 rounded-md w-full grid grid-cols-5 sm:grid-cols-7">
      {Array.from({ length: dateTo.diff(dateFrom, 'days').days + 1 }, function (_, i) {
            var day = dateFrom.plus({ days: i });
            return (<DayButton key={day.toISO()} date={day} onSelect={onDateSelected} selected={(selected === null || selected === void 0 ? void 0 : selected.toISO()) === day.toISO()}/>);
        })}
    </div>);
};
