import { useMemo, useState } from 'react'
import { formatMoney } from '../Money'
import { DateTime } from 'luxon'
import { useOffering } from '../AvailabilityCheck/Context'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../Spinner'
import { ChoiceOptionDetail } from './ChoiceOptionDetail'
import { Modal } from '../Modal'
import { CheckIcon } from '@heroicons/react/solid'
import { useFormatFromNumberOfMinutes } from '../Duration'
import { isEqual, map, uniq } from 'lodash'

const Duration = ({ mins }) => {
  const formatFromNumberOfMinutes = useFormatFromNumberOfMinutes(mins)

  return <div className="text-xs font-normal text-gray-500">{formatFromNumberOfMinutes}</div>
}

const OptionPriceChange = ({ amount, currency }) => (
  <>
    {' '}
    <span className="text-sm text-gray-500">+{formatMoney({ amount, currency })}</span>
  </>
)

const OptionPrice = ({ from, to, currency }) => {
  const { t } = useTranslation()

  return (
    <>
      {' '}
      <span className="text-sm text-gray-500">
        {from === to && formatMoney({ amount: from, currency })}
        {from !== to &&
          t('frontend.check_availability.package_config.from_price', {
            price: formatMoney({ amount: from, currency }),
          })}
      </span>
    </>
  )
}

const UnavailableChoiceOption = ({ name }) => {
  const { t } = useTranslation()

  return (
    <button
      className={`
        overflow-hidden group w-full text-left cursor-not-allowed
        focus:outline-none
        active:outline-none
        py-4
        flex justify-between items-start space-x-6
      `}
      disabled
    >
      <div className="mt-1 flex space-x-6">
        <div className="space-y-1">
          <div className="flex justify-between font-medium text-gray-800">
            <span className="break-normal line-through">{name}</span>
          </div>

          <div className="text-gray-500 text-sm">
            {t('frontend.check_availability.package_config.no_availability')}
          </div>
        </div>
      </div>

      <div className="w-[100px] mt-2 aspect-square overflow-hidden rounded-md bg-gray-100">
        <div className="h-full" />
      </div>
    </button>
  )
}

export const LoadingChoiceOption = () => (
  <button
    className={`
      overflow-hidden group w-full text-left cursor-not-allowed
      focus:outline-none
      active:outline-none
      py-4
      flex justify-between items-start space-x-6
    `}
    disabled
  >
    <div className="w-full mt-1 flex space-x-6">
      <div className="w-full space-y-1">
        <div className="flex justify-between font-medium text-gray-800">
          <div className="text-sm bg-gray-200 animate-pulse h-6 w-1/2" />
        </div>

        <div className="space-y-1">
          <div className="text-sm bg-gray-200 animate-pulse h-4 w-full" />
          <div className="text-sm bg-gray-200 animate-pulse h-4 w-2/3" />
          <div className="text-sm bg-gray-200 animate-pulse h-4 w-1/3" />
        </div>
      </div>
    </div>

    <div className="flex-shrink-0 w-[100px] mt-2 aspect-square overflow-hidden rounded-md bg-gray-100">
      <div className="h-full" />
    </div>
  </button>
)

export const ChoiceContainer = ({
  title,
  subtitle,
  description,
  image,
  children,
  onClick,
  maxGuests,
}) => {
  const { t } = useTranslation()

  return (
    <button
      className={`
      overflow-hidden group w-full text-left cursor-pointer
      focus:outline-none
      active:outline-none
      py-4
      flex justify-between items-start space-x-6
    `}
      onClick={onClick}
    >
      <div className="mt-1 flex space-x-6">
        <div className="space-y-1">
          <div className="font-medium text-gray-800">
            <div className="break-normal">{title}</div>
            {subtitle}
          </div>

          {children}

          {description && (
            <>
              <div
                className="text-gray-500 text-sm line-clamp-3"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </>
          )}

          {maxGuests && (
            <div className="text-gray-500 text-xs">
              {t('frontend.check_availability.package_config.room_max_guests', {
                count: maxGuests,
              })}
            </div>
          )}
        </div>
      </div>

      <div className="flex-shrink-0 w-[100px] mt-2 aspect-square overflow-hidden rounded-md bg-gray-100 flex items-center justify-center">
        {image && (
          <img src={image} className="w-full h-full aspect-square object-center object-cover" />
        )}
      </div>
    </button>
  )
}

export const ChoiceOptionV2 = ({
  choice,
  option,
  onRequireSlot,
  onAdd,
  onUpdateBasketItem,
  onRemove,
  choiceDate: date,
  requiresSlots,
  getSlotsHook,
  showFromPrice = false,
  itemsForOption = [],
  shouldShowPriceChange = false,
  autoSelectTimeslot = false,
  shouldShowTimes = false,
}) => {
  const { t } = useTranslation()
  const { basket, basketItemId, separateCouplesPackageChoices } = useOffering()
  const basketItem = basket.items.find((basketItem) => basketItem.id === basketItemId)
  const applied = itemsForOption[0]
  const appliedTimeslot = applied?.time ? DateTime.fromISO(applied.time) : null
  const guestIdsWithOption = itemsForOption.reduce((guestIds, item) => {
    return guestIds.concat(map(item.guests ?? basketItem?.guests, 'id'))
  }, [])
  const allGuestsHaveSameOption =
    (basketItem?.guests.length === 1 && itemsForOption.length === 1) ||
    (isEqual(guestIdsWithOption, map(basketItem?.guests, 'id')) &&
      uniq(map(itemsForOption, 'item_configuration.time')).length === 1)
  const packageItem = applied

  const [showDetail, setShowDetail] = useState(false)
  const [isMutating, setMutating] = useState(false)
  const [selectedDuration, setSelectedDuration] = useState(() => {
    if (option?.offering?.durations) {
      return option.offering.durations[0]
    }

    return null
  })

  const { isLoading, refetch, data: { data: packageSlots = [] } = {} } = getSlotsHook()

  const slots = useMemo(() => {
    let slotsForOption = packageSlots.find((s) => s.id === option.id)?.slots ?? []

    if (selectedDuration) {
      slotsForOption = slotsForOption.filter((s) => s.duration === selectedDuration)
    }

    return slotsForOption
  }, [packageSlots, selectedDuration])

  const suggestedSlots = packageSlots[0]?.suggested_slots ?? []

  const handleSelect = () => {
    if (requiresSlots && !autoSelectTimeslot) {
      onRequireSlot()
      refetch()
    }

    setShowDetail(true)
  }

  const handleRemove = async () => {
    setMutating(true)

    try {
      await onRemove()
    } catch {
      // Error is handled in parent component
    } finally {
      setMutating(false)
      setShowDetail(false)
    }
  }

  const handleConfirm = async (vals = undefined) => {
    setMutating(true)

    vals = vals ?? {}

    // If an appointment type gets here, it means this choice
    // is set up to auto-select a timeslot. We still need to
    // pass a time to the API, so use midnight of the selected date.
    if (option.item_type === 'appointment') {
      vals.time = date.toISO({ suppressMilliseconds: true })
    }

    try {
      await onAdd(vals)
    } catch {
      // Error is handled in parent component
    } finally {
      setMutating(false)
      setShowDetail(false)
    }
  }

  const handlePickTimeslot = async (timeslot) => {
    setMutating(true)

    const time = DateTime.fromJSDate(timeslot.start_time).toISO({ suppressMilliseconds: true })

    const slotDetails = {
      time,
      duration: timeslot.duration,
      guest_ids: timeslot.guest_ids,
      item_configuration: {
        time,
        duration: timeslot.duration,
        shared_basket_item_id: timeslot.shared_basket_item_id,
        option_ids: (timeslot.options ?? []).map((option) => option.id),
      },
    }

    // If we have a package item already and it's using a shared
    // basket item, we want to update the shared item rather
    // than updating the package item.
    if (packageItem?.shared_basket_item_id !== undefined) {
      await onUpdateBasketItem(packageItem.shared_basket_item_id, slotDetails)
      setMutating(false)
      setShowDetail(false)

      return
    }

    try {
      // If we already have this offering in the order, remove it
      // before adding it again at the newly selected time.
      // Fixes SC-15682
      if (allGuestsHaveSameOption) {
        await onRemove()
      }

      await onAdd(slotDetails)
    } catch {
      // Error is handled in parent component
    } finally {
      setMutating(false)
      setShowDetail(false)
    }
  }

  if (requiresSlots && isLoading) {
    return <LoadingChoiceOption name={option.offering.name} />
  }

  if (requiresSlots && (slots.length === 0 || slots.every((s) => s.quantity_available === 0))) {
    return <UnavailableChoiceOption name={option.offering.name} />
  }

  return (
    <div>
      <ChoiceContainer
        title={option.offering.name}
        subtitle={
          <>
            {!showFromPrice && shouldShowPriceChange && option.price_change > 0 && (
              <OptionPriceChange amount={option.price_change} currency={option.offering.currency} />
            )}
            {showFromPrice && (
              <OptionPrice
                from={option.offering.price_from}
                to={option.offering.price_to}
                currency={option.offering.currency}
              />
            )}
          </>
        }
        description={option.offering.description}
        image={option.offering.image?.url}
        onClick={handleSelect}
        maxGuests={option.offering.max_guests}
      >
        <div className="flex gap-x-2">
          {option.offering.duration && <Duration mins={option.offering.duration} />}
          {option.offering.min_guests === 2 && <CouplesTag />}
        </div>
      </ChoiceContainer>

      {!separateCouplesPackageChoices && applied && (
        <div className="flex items-center space-x-2 mb-4">
          <div className="text-accent border border-accent px-2 py-1 pr-3 rounded-full text-xs font-medium flex items-center space-x-1">
            <CheckIcon className="w-4 h-4" />
            <span>
              {t('frontend.check_availability.package_config.added')}
              {appliedTimeslot && shouldShowTimes ? (
                <> &middot; {appliedTimeslot.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase()}</>
              ) : (
                ''
              )}
            </span>
          </div>

          <button
            disabled={isMutating}
            className="text-sm hover:underline"
            onClick={handleRemove}
            title={t('frontend.basket_slideover.basket_item.remove')}
          >
            {isMutating && <Spinner margin="m-0" />}
            {!isMutating && t('frontend.basket_slideover.basket_item.remove')}
          </button>
        </div>
      )}
      {separateCouplesPackageChoices && itemsForOption.length > 0 && (
        <div className="flex flex-col gap-y-2 mb-4">
          {itemsForOption.map((addedOption) => (
            <div className="flex items-center space-x-2" key={addedOption.id}>
              <div className="text-accent border border-accent px-2 py-1 pr-3 rounded-full text-xs font-medium flex items-center space-x-1">
                <CheckIcon className="w-4 h-4" />
                <span>
                  {allGuestsHaveSameOption
                    ? t('frontend.check_availability.package_config.added')
                    : map(addedOption.guests, 'name').join(', ')}
                  {appliedTimeslot && shouldShowTimes ? (
                    <>
                      {' '}
                      &middot; {appliedTimeslot.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase()}
                    </>
                  ) : (
                    ''
                  )}
                </span>
              </div>

              <button
                disabled={isMutating}
                className="text-sm hover:underline"
                onClick={handleRemove}
                title={t('frontend.basket_slideover.basket_item.remove')}
              >
                {isMutating && <Spinner margin="m-0" />}
                {!isMutating && t('frontend.basket_slideover.basket_item.remove')}
              </button>
            </div>
          ))}
        </div>
      )}

      <Modal open={showDetail} onClose={() => setShowDetail(false)}>
        <ChoiceOptionDetail
          choice={choice}
          applied={applied}
          offering={option.offering}
          requiresSlots={requiresSlots && !autoSelectTimeslot}
          slots={slots}
          suggestedSlots={suggestedSlots}
          isLoading={isLoading}
          onGoBack={() => setShowDetail(false)}
          onPickTimeslot={handlePickTimeslot}
          onConfirm={handleConfirm}
          onRemove={handleRemove}
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
        />
      </Modal>
    </div>
  )
}

const CouplesTag = () => {
  const { t } = useTranslation()

  return (
    <span className="relative -top-[1px] ml-2 rounded-lg bg-gray-100 p-1 px-2 text-xs font-medium text-gray-500">
      {t('frontend.check_availability.package_config.for_couples')}
    </span>
  )
}
