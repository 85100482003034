import { useState } from 'react'
import { useOffering } from '../AvailabilityCheck/Context'
import { useAddToOrder, useRemoveFromOrder, usePackage } from '../OrderHooks'
import { AnimatePresence, motion } from 'framer-motion'
import { GuestConfig } from '../AvailabilityCheck/GuestConfig'
import { UserIcon } from '@heroicons/react/solid'
import { ConfigureForGuest } from './ConfigureForGuest'
import { OfferingModal } from '../OfferingModal'
import { useTranslation } from 'react-i18next'
import { ConfigureForGuestV2 } from './ConfigureForGuestV2'
import { useSiteName } from '../../useSiteName'

const animations = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  show: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -50,
  },
}

export const ConfigureModal = ({
  isOpen,
  onClose,
  onDone,
  requireMemberGuests = false,
  maxGuests,
  separateNameValuesEnforced = false,
}) => {
  const { t } = useTranslation()
  const [guestsSetUp, setGuestsSetUp] = useState(false)
  const [currentGuestIndex, setCurrentGuestIndex] = useState(0)
  const [purchasingGuests, setPurchasingGuests] = useState([])
  const [guestsSetupErrorMessage, setGuestsSetupErrorMessage] = useState(null)
  const {
    id,
    offeringType,
    offeringName,
    siteId,
    date,
    setBasket,
    setBasketId,
    setBasketItemId,
    isCouples,
    allowsSelectingHotelRoom,
  } = useOffering()
  const { mutateAsync: addToOrderAsync } = useAddToOrder(id, offeringType)
  const [basketItemIdsForGuests, setBasketItemIdsForGuests] = useState([])
  const [basketItemResponse, setBasketItemResponse] = useState(null)

  const { isLoading: isLoadingPackage, data: { data: packageData = {} } = {} } = usePackage(
    id,
    siteId,
    {
      refetchOnWindowFocus: false,
    }
  )

  const { mutate: removeItem } = useRemoveFromOrder()

  const handleCleanup = (withBasketRemoval = false) => {
    setGuestsSetUp(false)
    setCurrentGuestIndex(0)

    if (withBasketRemoval) {
      // Remove each basket item from the order.
      basketItemIdsForGuests.forEach((id) => removeItem(id))
    }

    setBasketItemIdsForGuests([])
  }

  const handleClose = () => {
    handleCleanup(true)
    onClose()
  }

  const handleGuestsSetUp = async (selectedGuests) => {
    // Add an item for each guest member and store the ID
    setPurchasingGuests(selectedGuests)
    setGuestsSetupErrorMessage(null)

    let basket, basketId, items, basketGuests

    try {
      const addResponse = await addToOrderAsync({
        selectedDate: date,
        guests: selectedGuests,
      })

      basket = addResponse
      basketId = addResponse.id
      items = addResponse.items
      basketGuests = addResponse.guests
    } catch (e) {
      setGuestsSetupErrorMessage(
        t('frontend.configure_package.configure_modal.setup_error_message')
      )
      return
    }

    setBasket(basket)
    setBasketId(basketId)

    if (window.setBasketGuests !== undefined) {
      window.setBasketGuests(basketGuests)
    }

    // We now need to store a map of group members to basket item IDs.
    // TODO: Make this less brittle.
    // We're assuming the last items in the basket are the ones we just
    // created, in the order the guests were specified here.
    // @willtj maybe we should discuss to come up with something better.
    const itemsForTheseGuests = items
      .filter((item) => item.offering_id === id)
      .slice(-purchasingGuests.length)

    setBasketItemIdsForGuests(itemsForTheseGuests.map((item) => item.id))
    setBasketItemId(itemsForTheseGuests[0].id)
    setBasketItemResponse(itemsForTheseGuests[0])
    setGuestsSetUp(true)
  }

  const handleGuestComplete = () => {
    const price = parseFloat((basketItemResponse.price / 100).toFixed(2))

    window.gtag('event', 'add_to_cart', {
      currency: basketItemResponse.currency.toUpperCase(),
      value: price,
      items: [
        {
          affiliation: useSiteName(),
          item_id: id,
          item_name: offeringName,
          quantity: 1, // We fire this event for each guest.
          price: price,
        },
      ],
    })

    if (
      currentGuestIndex < purchasingGuests.length - 1 &&
      !isCouples &&
      !allowsSelectingHotelRoom
    ) {
      setCurrentGuestIndex(currentGuestIndex + 1)
      setBasketItemId(basketItemIdsForGuests[currentGuestIndex])
    } else {
      onDone()
      handleCleanup()
    }
  }

  return (
    <OfferingModal isOpen={isOpen} onClose={handleClose}>
      <AnimatePresence>
        {!guestsSetUp && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <GuestConfig
              onComplete={handleGuestsSetUp}
              errorMessage={guestsSetupErrorMessage}
              requireMemberGuests={requireMemberGuests}
              maxGuests={maxGuests}
              separateNameValuesEnforced={separateNameValuesEnforced}
            />
          </motion.div>
        )}

        {guestsSetUp && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {purchasingGuests.length > 1 && !isCouples && !allowsSelectingHotelRoom && (
              <div className="bg-gray-100 px-8 py-4 text-gray-500">
                {t('frontend.configure_package.configure_modal.selecting_for')}{' '}
                <span className="font-medium">
                  <UserIcon className="w-4 h-4 mr-1 inline" />
                  {purchasingGuests[currentGuestIndex].is_lead_booker
                    ? `${t('frontend.configure_package.configure_modal.yourself')}`
                    : purchasingGuests[currentGuestIndex].name}
                </span>
              </div>
            )}

            {!isLoadingPackage && (
              <AnimatePresence>
                <motion.div
                  variants={animations}
                  initial="hidden"
                  animate="show"
                  key={`${id}_${currentGuestIndex}`}
                  transition={{
                    type: 'spring',
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <div className="p-6 px-3 md:px-4">
                    {window.featureFlags.includes('package_config_tweaks') && (
                      <ConfigureForGuestV2
                        basketItemId={basketItemIdsForGuests[currentGuestIndex]}
                        choices={packageData.item_choices}
                        onComplete={handleGuestComplete}
                        initialBudgets={basketItemResponse.option_budgets}
                      />
                    )}

                    {!window.featureFlags.includes('package_config_tweaks') && (
                      <ConfigureForGuest
                        basketItemId={basketItemIdsForGuests[currentGuestIndex]}
                        choices={packageData.item_choices}
                        onComplete={handleGuestComplete}
                        initialBudgets={basketItemResponse.option_budgets}
                      />
                    )}
                  </div>
                </motion.div>
              </AnimatePresence>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </OfferingModal>
  )
}
