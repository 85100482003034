import {
  useAddToOrder,
  useOrder,
  useGetVoucherDeliveryOptions,
  useUpdateItem,
  useGetVoucherDeliveryOption,
} from './OrderHooks'
import { QueryClient, QueryClientProvider } from 'react-query'
import { OfferingProvider, useOffering } from './AvailabilityCheck/Context'
import { OfferingModal } from './OfferingModal'
import { useState, useRef, useEffect } from 'react'
import { ContinueButton } from './AvailabilityCheck/GuestConfig'
import { useTranslation } from 'react-i18next'
import { useSiteName } from '../useSiteName'
import { DateSelectorMinDate } from './HeroCta'
import { DateTime } from 'luxon'
import { Listbox, RadioGroup } from '@headlessui/react'
import { SelectorIcon, TruckIcon } from '@heroicons/react/solid'
import { formatMoney } from './Money'
import classNames from 'classnames'
import { addressDetailsClasses } from './GoogleAddressAutoComplete'
import { GoogleAddressAutoComplete } from './GoogleAddressAutoComplete'
import { APIProvider } from '@vis.gl/react-google-maps'

const queryClient = new QueryClient()

export const VoucherForm = ({ countryCode, isOpen, onClose, basketItem, countryName }) => {
  const PHYSICAL_DELIVERY_METHOD = 'physical'
  const DIGITAL_DELIVERY_METHOD = 'email'
  const MIN_DATE = DateTime.now().plus({ days: 1 }).toJSDate()
  const { id, price } = useOffering()
  const [recipientName, setRecipientName] = useState('')
  const [recipientEmail, setRecipientEmail] = useState('')
  const [sendImmediately, setSendImmediately] = useState(true)
  const [customDeliveryDate, setCustomDeliveryDate] = useState(MIN_DATE)
  const [customMessage, setCustomMessage] = useState('')
  const [deliveryMethodSelected, setDeliveryMethodSelected] = useState(DIGITAL_DELIVERY_METHOD)
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [town, setTown] = useState('')
  const [county, setCounty] = useState('')
  const [postcode, setPostcode] = useState('')
  const [country, setCountry] = useState(countryName ?? '')
  const [physicalVoucherDeliveryMethod, setPhysicalVoucherDeliveryMethod] = useState(null)

  const [displayError, setDisplayError] = useState(null)
  const { t } = useTranslation()

  const { isVoucherLoading, mutate } = useAddToOrder(id, 'voucher')
  const { isLoading: isUpdating, isUpdateError, error, mutate: updateItem } = useUpdateItem()
  const { isOrderLoading, isError, refetch, data: { data: basket = {} } = {} } = useOrder()
  const { isDeliveryOptionsLoading, data: { data: voucherDeliveryOptions = [] } = {} } =
    useGetVoucherDeliveryOptions({
      refetchOnWindowFocus: false,
    })
  const {
    isLoading,
    isSuccess,
    data: { data: voucherDeliveryOption } = {},
  } = useGetVoucherDeliveryOption(basketItem?.item_configuration?.delivery_option_id)

  const addressLine1Ref = useRef(null)
  const addressLine2Ref = useRef(null)
  const townRef = useRef(null)
  const countyRef = useRef(null)
  const postcodeRef = useRef(null)
  const countryRef = useRef(null)

  useEffect(() => {
    if (basketItem?.id) {
      setFormValues()
      if (isSuccess && voucherDeliveryOption) {
        setPhysicalVoucherDeliveryMethod(voucherDeliveryOption)
      }
    }
  }, [basketItem, isSuccess, voucherDeliveryOption])

  //handling re-renders due to heirarchy of input fields
  useEffect(() => {
    if (addressLine1Ref.current) {
      addressLine1Ref.current.focus()
    }
  }, [addressLine1])

  useEffect(() => {
    if (addressLine2Ref.current) {
      addressLine2Ref.current.focus()
    }
  }, [addressLine2])

  useEffect(() => {
    if (townRef.current) {
      townRef.current.focus()
    }
  }, [town])

  useEffect(() => {
    if (countyRef.current) {
      countyRef.current.focus()
    }
  }, [county])

  useEffect(() => {
    if (postcodeRef.current) {
      postcodeRef.current.focus()
    }
  }, [postcode])

  const resetFormValues = () => {
    setRecipientName('')
    setRecipientEmail('')
    setSendImmediately(true)
    setCustomDeliveryDate(MIN_DATE)
    setCustomMessage('')
    setDeliveryMethodSelected(DIGITAL_DELIVERY_METHOD)
    setAddressLine1('')
    setAddressLine2('')
    setTown('')
    setCounty('')
    setPostcode('')
    setCountry(countryName ?? '')
    setPhysicalVoucherDeliveryMethod(null)
  }

  const setFormValues = () => {
    setRecipientName(basketItem?.item_configuration?.recipient_name)
    setRecipientEmail(basketItem?.item_configuration?.recipient_email)
    setDeliveryMethodSelected(basketItem?.item_configuration?.delivery_method_type)
    setCustomMessage(basketItem?.item_configuration?.custom_message)
    setAddressLine1(basketItem?.item_configuration?.address_line1)
    setAddressLine2(basketItem?.item_configuration?.address_line2)
    setTown(basketItem?.item_configuration?.town)
    setCounty(basketItem?.item_configuration?.county)
    setPostcode(basketItem?.item_configuration?.postcode)
    setCountry(basketItem?.item_configuration?.country)
  }

  const handleCloseModal = () => {
    resetFormValues()
    setDisplayError(null)
    onClose()
  }
  const handleAdd = () => {
    setDisplayError(null)
    const delivery_date = sendImmediately ? null : customDeliveryDate

    const mutateObjectWithFeatureFlagEnabled = {
      recipient_name: recipientName,
      recipient_email: recipientEmail,
      delivery_date: delivery_date,
      custom_message: customMessage,
      address_line1: addressLine1,
      address_line2: addressLine2,
      town: town,
      county: county,
      postcode: postcode,
      country: country,
      delivery_method_type: deliveryMethodSelected,
      delivery_option_id:
        physicalVoucherDeliveryMethod !== null ? physicalVoucherDeliveryMethod.id : '',
    }

    const mutateObjectWithFeatureFlagDisabled = {
      recipient_name: recipientName,
      recipient_email: recipientEmail,
      custom_message: customMessage,
    }

    mutate(
      {
        item_configuration: window.featureFlags.includes('voucher_delivery_method')
          ? mutateObjectWithFeatureFlagEnabled
          : mutateObjectWithFeatureFlagDisabled,
      },
      {
        onSuccess: (e) => {
          const item = e.items.find((item) => item.offering_id === id)

          if (window.gtag) {
            window.gtag('event', 'add_to_cart', {
              currency: e.currency,
              value: parseFloat((price / 100).toFixed(2)),
              items: [
                {
                  affiliation: useSiteName(),
                  item_id: item.offering_id,
                  item_name: item.offering_name,
                  quantity: 1,
                  price: parseFloat((item.price / 100).toFixed(2)),
                },
              ],
            })
          }

          window.openSlideover()
          window.setBasket(e)
          resetFormValues()
          onClose()
        },
        onError: (e) => {
          setDisplayError(e.message)
        },
      }
    )
  }

  const handleUpdate = () => {
    const payload = {
      item_configuration: {
        recipient_name: recipientName,
        recipient_email: recipientEmail,
        custom_message: customMessage,
        delivery_date: customDeliveryDate,
        address_line1: addressLine1,
        address_line2: addressLine2,
        town: town,
        county: county,
        postcode: postcode,
        country: country,
        delivery_method_type: deliveryMethodSelected,
        delivery_option_id:
          physicalVoucherDeliveryMethod !== null ? physicalVoucherDeliveryMethod.id : '',
      },
    }

    updateItem(
      {
        basketItemId: basketItem.id,
        values: payload,
      },
      {
        onSuccess: (e) => {
          window.setBasket(e)
          resetFormValues()
          onClose()
        },
      }
    )
  }

  const handleDeliveryStatusChange = (value) => {
    setSendImmediately(value)
  }

  const handleDeliveryDateChange = (value) => {
    setCustomDeliveryDate(value)
  }

  const handleDeliveryMethodChange = (value) => {
    if (value === PHYSICAL_DELIVERY_METHOD) {
      setSendImmediately(false)
      setCustomDeliveryDate('')
    } else {
      resetFormValues()
    }
    setDeliveryMethodSelected(value)
  }

  const handleAddressFetch = (addressProperties) => {
    let number = addressProperties.address_components.find((component) =>
      component.types.includes('street_number')
    )
    let street = addressProperties.address_components.find((component) =>
      component.types.includes('route')
    )
    let town = addressProperties.address_components.find((component) =>
      component.types.includes('postal_town')
    )
    let county = addressProperties.address_components.find((component) =>
      component.types.includes('administrative_area_level_2')
    )
    let postCode = addressProperties.address_components.find((component) =>
      component.types.includes('postal_code')
    )
    let country = addressProperties.address_components.find((component) =>
      component.types.includes('country')
    )
    setCountry(country.long_name)
    setPostcode(postCode.long_name)
    setAddressLine1(`${number?.long_name}, ${street?.long_name}`)
    setAddressLine2('')
    setCounty(county.long_name)
    setTown(town.long_name)
  }

  const DeliveryOptionSelect = () => {
    return (
      <Listbox
        as="div"
        value={physicalVoucherDeliveryMethod}
        onChange={(val) => {
          setPhysicalVoucherDeliveryMethod(val)
        }}
        className="relative"
      >
        <Listbox.Button className="flex items-center space-x-1 rounded-md border border-gray-300 px-3 py-2 pr-1 text-gray-500 bg-white">
          <TruckIcon className="w-4 h-4 text-accent" />
          <span>
            {physicalVoucherDeliveryMethod === null && (
              <span
                className="hidden sm:inline"
                dangerouslySetInnerHTML={{
                  __html: t('frontend.add_voucher_button.delivery_option', {}),
                }}
              />
            )}
            {physicalVoucherDeliveryMethod !== null && (
              <span
                className="hidden sm:inline"
                dangerouslySetInnerHTML={{
                  __html:
                    physicalVoucherDeliveryMethod.name +
                    ' + ' +
                    '(' +
                    formatMoney({
                      amount: physicalVoucherDeliveryMethod.price,
                      currency: basket.currency,
                    }) +
                    ')',
                }}
              />
            )}
          </span>
          <SelectorIcon className="w-5 h-5 text-gray-500" />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 mt-1 w-52 right-0 max-h-60 rounded-md p-1 bg-white shadow-lg border border-gray-300 focus:outline-none">
          {voucherDeliveryOptions.map((option, index) => (
            <Listbox.Option key={index} value={option}>
              {({ active, selected }) => (
                <div
                  className={`${
                    active ? 'text-accent bg-accent/10' : 'text-gray-900'
                  } cursor-pointer select-none relative py-2 pl-3 pr-9`}
                >
                  <span className={`${selected ? 'font-semibold' : 'font-normal'} block text-sm`}>
                    {option.name +
                      ' + ' +
                      '(' +
                      formatMoney({ amount: option.price, currency: basket.currency }) +
                      ')'}
                  </span>
                  <span
                    className={`${
                      selected ? 'text-accent' : 'text-gray-500'
                    } absolute inset-y-0 right-0 flex items-center pr-4`}
                  >
                    {' '}
                  </span>
                </div>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    )
  }

  const PhysicalDeliveryDetails = ({ onAddressFetch }) => {
    const googleApiKey = process.env.MIX_GOOGLE_API_KEY

    return (
      <div>
        <div className="py-2">
          <>
            <APIProvider apiKey={googleApiKey}>
              <GoogleAddressAutoComplete onPlaceSelect={onAddressFetch} countryCode={countryCode} />
            </APIProvider>
            <div>
              <input
                name="address"
                key="addressLine1"
                placeholder={t('frontend.add_voucher_button.address_line_1')}
                type="text"
                ref={addressLine1Ref}
                value={addressLine1}
                onChange={(val) => setAddressLine1(val.target.value)}
                className={classNames(addressDetailsClasses, 'w-full block my-2 rounded-md')}
              />
              <input
                name="address2"
                key="addressLine2"
                placeholder={t('frontend.add_voucher_button.address_line_2')}
                type="text"
                ref={addressLine2Ref}
                value={addressLine2}
                onChange={(val) => setAddressLine2(val.target.value)}
                className={classNames(addressDetailsClasses, 'w-full block my-2 rounded-md')}
              />
              <div className="grid md:grid-cols-2 grid-rows-2 sm:grid-cols-1 gap-2">
                <input
                  key="town"
                  name="town"
                  placeholder={t('frontend.add_voucher_button.town')}
                  type="text"
                  value={town}
                  ref={townRef}
                  onChange={(val) => setTown(val.target.value)}
                  className={classNames(addressDetailsClasses)}
                />
                <input
                  key="county"
                  name="county"
                  placeholder={t('frontend.add_voucher_button.county')}
                  type="text"
                  value={county}
                  ref={countyRef}
                  onChange={(val) => setCounty(val.target.value)}
                  className={classNames(addressDetailsClasses)}
                />
                <input
                  key="postcode"
                  name="postcode"
                  placeholder={t('frontend.add_voucher_button.postcode')}
                  type="text"
                  value={postcode}
                  ref={postcodeRef}
                  onChange={(val) => setPostcode(val.target.value)}
                  className={classNames(addressDetailsClasses)}
                />
                <input
                  key="country"
                  name="country"
                  placeholder={t('frontend.add_voucher_button.country')}
                  type="text"
                  ref={countryRef}
                  disabled
                  value={country}
                  className={classNames(addressDetailsClasses)}
                />
              </div>
            </div>
          </>
          <div className="md:w-2/3 mt-4">
            <DeliveryOptionSelect />
          </div>
        </div>
      </div>
    )
  }

  const CustomVoucherEmailDeliverySection = ({
    onDeliveryStatusChange,
    onDeliveryDateChange,
    value,
  }) => {
    const classNames = (...classes) => classes.filter(Boolean).join(' ')

    return (
      <RadioGroup
        id={'emailDeliveryMethods'}
        value={value}
        onChange={(value) => onDeliveryStatusChange(value)}
      >
        <RadioGroup.Label className="sr-only">Delivery Timing</RadioGroup.Label>
        <div className="bg-white rounded-md -space-y-px">
          {[
            { name: t('frontend.add_voucher_button.send_immediately'), value: true },
            { name: t('frontend.add_voucher_button.schedule_send'), value: false },
          ].map(({ value, name }, index) => (
            <RadioGroup.Option
              key={index}
              value={value}
              className={({ checked }) =>
                classNames(
                  index === 0 ? 'rounded-t-md' : '',
                  index === 1 ? 'rounded-b-md' : '',
                  checked ? 'bg-accent/10 border-accent-200 z-10' : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked ? 'bg-accent border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-accent' : '',
                      'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={'text-gray-900 block text-sm font-medium'}
                    >
                      {name}
                    </RadioGroup.Label>
                    {checked && !value && (
                      <>
                        <div className="">
                          <DateSelectorMinDate
                            value={customDeliveryDate}
                            minDate={MIN_DATE}
                            onChange={(value) => onDeliveryDateChange(value)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    )
  }

  const DeliveryMethodOption = ({ deliveryOption }) => {
    return (
      <RadioGroup.Option
        className={({ checked }) =>
          `${
            checked ? 'ring ring-offset-1 ring-accent' : ''
          } rounded-md border border-gray-300 shadow-sm p-3 flex items-start bg-white`
        }
        value={deliveryOption.id}
      >
        {({ checked }) => (
          <>
            <div className="flex-1">
              <RadioGroup.Label className="text-sm font-small truncate">
                {deliveryOption.title}
              </RadioGroup.Label>
              <RadioGroup.Description
                as="span"
                className="mt-1 flex items-center text-sm text-gray-500"
              >
                {deliveryOption.description}
              </RadioGroup.Description>
            </div>

            {checked && (
              <div className="flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7 text-accent"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </>
        )}
      </RadioGroup.Option>
    )
  }

  const DeliveryMethodSelect = ({ onChange, value }) => {
    const deliveryOptions = [
      {
        id: PHYSICAL_DELIVERY_METHOD,
        title: 'Mail',
        description: t('frontend.add_voucher_button.physical_delivery_description'),
      },
      {
        id: DIGITAL_DELIVERY_METHOD,
        title: 'Email',
        description: t('frontend.add_voucher_button.digital_delivery_description'),
      },
    ]

    const validDeliveryOptions =
      voucherDeliveryOptions.length > 0
        ? deliveryOptions
        : deliveryOptions.filter((option) => option.id !== PHYSICAL_DELIVERY_METHOD)

    const getValidDeliveryOptionsStyling = () => {
      return validDeliveryOptions.length === 1
        ? 'grid grid-cols-1 md:grid-cols-1 gap-6 my-4 w-full'
        : 'grid grid-cols-1 md:grid-cols-2 gap-6 my-4 w-full'
    }

    return (
      <RadioGroup
        id={'deliveryMethods'}
        value={value}
        onChange={(val) => {
          onChange(val)
        }}
        className="block w-full"
      >
        <div className={getValidDeliveryOptionsStyling()}>
          {validDeliveryOptions.map((option, index) => (
            <DeliveryMethodOption deliveryOption={option} key={index} />
          ))}
        </div>
      </RadioGroup>
    )
  }

  const CustomDeliveryMethodSection = ({ onAddressFetch }) => {
    return (
      <div>
        <DeliveryMethodSelect
          value={deliveryMethodSelected}
          onChange={(deliveryOption) => {
            handleDeliveryMethodChange(deliveryOption)
          }}
        />
        {deliveryMethodSelected === PHYSICAL_DELIVERY_METHOD && (
          <PhysicalDeliveryDetails onAddressFetch={onAddressFetch} />
        )}
        {deliveryMethodSelected === DIGITAL_DELIVERY_METHOD && (
          <CustomVoucherEmailDeliverySection
            onDeliveryDateChange={handleDeliveryDateChange}
            onDeliveryStatusChange={handleDeliveryStatusChange}
            value={sendImmediately}
          />
        )}
      </div>
    )
  }

  return (
    <OfferingModal isOpen={isOpen} onClose={() => handleCloseModal()}>
      <div className="p-6 space-y-6">
        <div className="mb-6 flex flex-col space-y-6">
          {displayError && <div className="text-sm text-red-700">{displayError}</div>}
          <div>
            <div className="text-xl font-medium">
              {t('frontend.add_voucher_button.who_is_this_for')}
            </div>
            <div className="text-sm text-gray-500 mt-1">
              {t('frontend.add_voucher_button.well_send_the_voucher')}
            </div>
          </div>

          <div>
            <label htmlFor="recipient_name" className="block text-sm font-medium text-gray-700">
              {t('frontend.add_voucher_button.recipient_name')}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="recipient_name"
                id="recipient_name"
                className="w-full rounded border-gray-300 shadow-sm disabled:opacity-50 focus:outline-none focus:ring focus:ring-accent focus:border-gray-300"
                placeholder={t('frontend.add_voucher_button.recipient_name')}
                value={recipientName}
                onChange={(e) => setRecipientName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label htmlFor="recipient_name" className="block text-sm font-medium text-gray-700">
              {t('frontend.add_voucher_button.recipient_email')}
            </label>
            <div className="mt-1">
              <input
                type="email"
                name="recipient_email"
                id="recipient_email"
                className="w-full rounded border-gray-300 shadow-sm disabled:opacity-50 focus:outline-none focus:ring focus:ring-accent focus:border-gray-300"
                placeholder={t('frontend.add_voucher_button.recipient_email')}
                value={recipientEmail}
                onChange={(e) => setRecipientEmail(e.target.value)}
              />

              <div className="text-sm text-gray-500 mt-2">
                {t('frontend.add_voucher_button.dont_want_to_spoil')}
              </div>
            </div>
            {window.featureFlags.includes('voucher_delivery_method') && (
              <div>
                <CustomDeliveryMethodSection onAddressFetch={handleAddressFetch} />
              </div>
            )}
          </div>
          <div>
            <label htmlFor="custom_message" className="block text-sm font-medium text-gray-700">
              {t('frontend.add_voucher_button.custom_message')}
            </label>
            <div className="mt-1">
              <textarea
                type="text"
                name="custom_message"
                id="custom_message"
                className="w-full rounded border-gray-300 shadow-sm disabled:opacity-50 focus:outline-none focus:ring focus:ring-accent focus:border-gray-300"
                placeholder={t('frontend.add_voucher_button.custom_message')}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
        <ContinueButton
          disabled={isVoucherLoading}
          onClick={basketItem ? handleUpdate : handleAdd}
          loading={isVoucherLoading}
          label={basketItem ? 'Edit voucher' : t('frontend.add_voucher_button.add_to_order')}
        />
      </div>
    </OfferingModal>
  )
}

export const AddVoucherButton = (props) => {
  const { t } = useTranslation()
  const [isVoucherFormOpen, setIsVoucherFormOpen] = useState(false)

  const handleOpen = () => setIsVoucherFormOpen(true)
  const handleClose = () => setIsVoucherFormOpen(false)

  return (
    <QueryClientProvider client={queryClient}>
      <OfferingProvider
        id={props.offeringId}
        basketGuests={props.guests ? JSON.parse(props.guests) : []}
        price={props.price}
        offeringName={props.offeringName}
        offeringImage={props.offeringImage}
      >
        <button
          className="relative bg-accent overflow-hidden w-full text-sm text-on-accent font-medium p-3 rounded-md hover:bg-accent/10"
          onClick={handleOpen}
        >
          {t('frontend.add_voucher_button.add_to_order')}
        </button>
        <VoucherForm
          isOpen={isVoucherFormOpen}
          onClose={handleClose}
          countryCode={props.defaultCountryCode}
          countryName={props.defaultCountryName}
        />
      </OfferingProvider>
    </QueryClientProvider>
  )
}
