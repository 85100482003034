import { DateTime } from 'luxon'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useState } from 'react'
import { DatePicker } from './DatePicker'
import { GuestInput } from './GuestInput'
import { CheckAvailabilityButton } from './CheckAvailabilityButton'
import { ConfigurePackageButton } from '../ConfigurePackage/ConfigurePackageButton'
import { formatMoney } from '../Money'
import { OfferingProvider } from './Context'
import { useTranslation } from 'react-i18next'

const queryClient = new QueryClient()

export const AvailabilityCheck = ({
  siteId,
  offeringId,
  offeringType,
  durations,
  offeringName,
  date,
  offeringImage,
  fromAmount,
  currency,
  guests,
  minGuests,
  maxGuests,
  separateNameValuesEnforced,
  separateCouplesPackageChoices,
  requireMemberGuests,
  basketId,
  enquiriesEnabled,
  allowsSelectingHotelRoom,
  numberOfNights,
}) => {
  const existingDate = date ? DateTime.fromFormat(date, 'yyyy-MM-dd') : null
  const [selectedDate, setSelectedDate] = useState(existingDate?.isValid ? existingDate : null)
  const { t } = useTranslation()

  return (
    <QueryClientProvider client={queryClient}>
      <OfferingProvider
        id={offeringId}
        durations={durations ? JSON.parse(durations) : []}
        siteId={siteId}
        offeringType={offeringType}
        offeringName={offeringName}
        offeringImage={offeringImage}
        date={selectedDate}
        basketGuests={guests ? JSON.parse(guests) : []}
        isCouples={parseInt(minGuests) === 2 && parseInt(maxGuests) === 2}
        allowsSelectingHotelRoom={allowsSelectingHotelRoom}
        numberOfNights={numberOfNights}
        currency={currency}
        basketId={basketId}
        enquiriesEnabled={enquiriesEnabled === 'true'}
        separateCouplesPackageChoices={separateCouplesPackageChoices === 'true'}
        checkOutDate={
          allowsSelectingHotelRoom ? selectedDate?.plus({ days: numberOfNights }) : null
        }
      >
        <div className="flex justify-between mb-4 border-b pb-4 flex-wrap">
          <div
            className="text-gray-500 text-sm flex-1"
            dangerouslySetInnerHTML={{
              __html: t('frontend.from_price_js', {
                price: formatMoney({ amount: fromAmount, currency }),
              }).replace(
                /\*\*(.*?)\*\*/g,
                '<span class="block text-xl font-medium text-gray-800">$1</span>'
              ),
            }}
          />
          <div className="flex-1">
            <GuestInput maxGuests={maxGuests} />
          </div>
        </div>

        <DatePicker onChange={setSelectedDate} value={selectedDate} />

        {offeringType === 'package' && (
          <ConfigurePackageButton
            requireMemberGuests={requireMemberGuests === 'true'}
            maxGuests={maxGuests}
            separateNameValuesEnforced={separateNameValuesEnforced === 'true'}
          />
        )}

        {offeringType !== 'package' && (
          <CheckAvailabilityButton
            requireMemberGuests={requireMemberGuests === 'true'}
            maxGuests={maxGuests}
            separateNameValuesEnforced={separateNameValuesEnforced === 'true'}
          />
        )}
      </OfferingProvider>
    </QueryClientProvider>
  )
}
